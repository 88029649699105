"use client";
import Image from "next/image";
import { useRouter } from "next/navigation";
import PrimaryButton from "../lego/Button";
import styles from "./notification.module.css";

export default function Error() {
    const router = useRouter();
    return (
        <section className={styles.wrapper}>
            <Image
                src="/image/notification/error.webp"
                width={280}
                height={280}
                alt="error"
                unoptimized
            />
            <p className={styles.text}>
                Ooops! There has been an error! <br />
                If you have encountered an error with your booking, you can
                contact us via email, whatsapp or online form.{" "}
            </p>

            <div className={styles.buttongroup}>
                <PrimaryButton onClick={() => router.push("/contact")}>
                    Contact us
                </PrimaryButton>{" "}
                <PrimaryButton onClick={() => router.push("/")}>
                    Browse trips
                </PrimaryButton>
            </div>
        </section>
    );
}
